import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'

import Header from 'components/sections/main-header'
import Seo from 'components/layout/seo'

import AurecIdea from 'components/sections/aurec-idea'
import Newsletter from 'components/sections/newsletter'
import WhoWeAre from 'components/sections/who-we-are'
import AurecPlaces from 'components/sections/aurec-places'
// import SlideGallery from 'components/sections/slide-gallery'
import AskAboutFlats from 'components/ask-about-flats'
import EventsCards from 'components/sections/events-cards'

import type { IndexPageQuery } from 'types/graphql'

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.mainPage

  const HERO_IMAGE = {
    src: PAGE?.heroImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: PAGE?.heroImg?.altText!,
  }

  const PLACES = PAGE?.assets?.map((place) => ({
    name: place?.assetsTitleSingle!,
    location: place?.assetsSubtitleSingle!,
    image: {
      src: place?.assetsImgSingle?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: place?.assetsImgSingle?.altText!,
    },
    icon: place?.assetsIconSingle,
  }))

  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <AskAboutFlats />
      <Header
        image={HERO_IMAGE}
        title={PAGE?.heroTitle!}
        noDiscoverButton
        hasPeopleImg
      />
      <AurecIdea
        image={{
          src: PAGE?.aboutImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.aboutImg?.altText!,
        }}
        title={PAGE?.aboutTitle!}
        description={PAGE?.aboutText!}
      />
      <AurecPlaces places={PLACES} />
      {/* <EventsCards title="Aktywizujemy się" /> */}
      <Newsletter />
      <WhoWeAre
        image={{
          src: PAGE?.whoWeAreImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.whoWeAreImg?.altText!,
        }}
        title={PAGE?.whoWeAreTitle!}
        description={PAGE?.whoWeAreDescription!}
      />
      {/* <SlideGallery /> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage(slug: { regex: "/strona-glowna/" }) {
      id
      mainPage {
        aboutText
        aboutTitle
        galleryTitle
        galleryUrl
        heroTitle
        whoWeAreDescription
        whoWeAreTitle
        whoWeAreImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        aboutImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        assets {
          assetsIconLabel
          assetsIconSingle
          assetsSubtitleSingle
          assetsTitleSingle
          assetsImgSingle {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
        }
        galleryImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        heroImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
