import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import media from 'styles/media'

import arrowRight from 'assets/icons/arrow-right.svg'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type WhoWeAreProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  title: string
  description: string
}

const StyledWrapper = styled.div`
  padding: 0 20px;
  max-width: 1400px;
  margin-top: 0;

  display: flex;
  flex-direction: column-reverse;

  ${media.lg.min} {
    padding: 0 50px;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    gap: 50px;
  }
`

const StyledImageWrapper = styled.div`
  img {
    ${media.md.min} {
      width: 80%;
    }
  }
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  margin-top: 30px;

  ${media.lg.min} {
    margin-top: 0;
    max-width: 600px;
  }
`

const StyledHeading = styled.h1`
  font-size: 42px;
  margin-bottom: 30px;
`

const StyledDescription = styled.p`
  margin-bottom: 60px;
`

const StyledButton = styled.button`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.orange};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 34px;
  padding: 16px 0;
  line-height: normal;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  border: ${({ theme }) => `2px solid ${theme.colors.orange}`};
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  svg {
    position: absolute;
    right: 27px;

    path {
      transition: 0.3s;
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${media.lg.min} {
    width: 200px;
  }
`

const WhoWeAre: React.FC<WhoWeAreProps> = ({ image, title, description }) => {
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledHeading>{title}</StyledHeading>
        <StyledDescription>{description}</StyledDescription>
        <StyledButton as={Link} to="/kim-jestesmy/">
          <p>Zacznij</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.779"
            height="14.779"
            viewBox="0 0 14.779 14.779"
          >
            <path
              id="Path_195"
              data-name="Path 195"
              d="M7.39,0,6.082,1.307l5.159,5.159H0V8.313H11.241L6.082,13.472,7.39,14.778l7.389-7.389Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </svg>
        </StyledButton>
      </StyledContentWrapper>
      <StyledImageWrapper>
        <GatsbyImage image={image.src} alt={image.alt} />
      </StyledImageWrapper>
    </StyledWrapper>
  )
}

export default WhoWeAre
