import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

import media from 'styles/media'

type AurecIdeaProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  title: string
  description: string
}

const StyledWrapper = styled.section`
  padding: 0 20px;
  max-width: 1400px;
  margin-top: 50px;
  position: relative;

  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    padding: 0 50px;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    gap: 50px;
  }

  ${media.xl.min} {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 470px;
      height: 470px;
      transform: translate(85%, -10%);
      border: 1px solid #dd001a;
      border-radius: 50%;
      right: 0;
      top: 0;
    }
  }
`

const StyledImageWrapper = styled.div`
  img {
    ${media.md.min} {
      width: 80%;
    }
  }
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2.5;

  ${media.lg.min} {
    max-width: 600px;
  }
`

const StyledHeading = styled.h1`
  font-size: 32px;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 41px;
`

const StyledDescription = styled.p`
  margin-bottom: 60px;
`

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 20px;
  line-height: normal;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.colors.green}`};
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  ${media.lg.min} {
    max-width: 200px;
  }
`

const AurecIdea: React.FC<AurecIdeaProps> = ({ image, title, description }) => {
  return (
    <StyledWrapper id="aurec-idea">
      <StyledImageWrapper>
        <GatsbyImage image={image.src} alt={image.alt} />
      </StyledImageWrapper>

      <StyledContentWrapper>
        <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
        <StyledDescription>{description}</StyledDescription>
        <StyledButton as={Link} to="/czym-jest-aurec/">
          Zobacz więcej
        </StyledButton>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default AurecIdea
